import React from "react";

function Icon() {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width="893.867"
      height="328.58"
      data-name="Layer 1"
      viewBox="0 0 893.867 645.616"
      style={{maxWidth: '100%'}}
    >
      <path
        fill="#f2f2f2"
        d="M710.155 772.465c8.8-19.007 17.592-38.294 21.411-58.888s2.216-42.942-8.721-60.804-32.473-29.974-53.024-25.936c-16.88 3.316-30.433 16.61-38.37 31.873s-11.12 32.476-14.206 49.4c-.998-19.226-2.054-38.762-8.44-56.923s-18.963-35.086-36.886-42.114-41.239-1.5-50.685 15.274c-13.309 23.632 4.338 56.782-10.965 79.175-2.625-22.462-26.114-40.209-48.438-36.596s-39.02 27.86-34.427 50.004c2.733 13.176 11.723 24.383 22.47 32.48s23.249 17.48 35.615 22.787z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#fff"
        d="M439.126 682.795c17.733 6.199 35.63 12.467 51.819 22.158 14.51 8.685 27.43 20.379 35.59 35.354a62.87 62.87 0 017.777 26.122c.117 1.918 3.118 1.93 3 0-1.05-17.08-9.373-32.708-20.898-45.069-12.636-13.552-29.01-22.807-45.917-29.989-10.024-4.258-20.297-7.876-30.574-11.468-1.826-.639-2.611 2.258-.797 2.892zM541.412 612.051a270.255 270.255 0 0125.995 57.706 273.524 273.524 0 0111.99 62.14 270.113 270.113 0 01.285 35.506c-.112 1.928 2.889 1.922 3 0a273.67 273.67 0 00-3.846-63.747 276.936 276.936 0 00-18.413-61.145 270.01 270.01 0 00-16.42-31.974 1.501 1.501 0 00-2.59 1.514zM688.244 629.284a437.368 437.368 0 00-32.262 108.527q-2.53 15.766-3.917 31.688c-.168 1.923 2.833 1.911 3 0a435.796 435.796 0 0124.01-109.37q5.34-14.875 11.76-29.331c.776-1.75-1.808-3.277-2.591-1.514z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#f2f2f2"
        d="M259.136 770.22a141.78 141.78 0 01-106.053-139.393c.122-7.912.954-15.99 4.28-23.17s9.504-13.39 17.232-15.092c8.433-1.857 17.333 1.939 23.618 7.861s10.407 13.736 14.198 21.495c22.838 46.734 40.19 96.88 46.686 148.488z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#fff"
        d="M170.768 594.124l16.006 36.368 16.006 36.367c5.07 11.517 10.129 23.04 15.369 34.481 5.193 11.341 10.564 22.603 16.295 33.684 5.722 11.065 11.804 21.95 18.405 32.515q1.233 1.974 2.49 3.932c.58.905 2.03.066 1.446-.845-6.728-10.487-12.927-21.305-18.741-32.324-5.821-11.03-11.261-22.257-16.5-33.574-5.268-11.377-10.333-22.847-15.384-34.321q-7.975-18.117-15.948-36.235l-16.006-36.367-1.992-4.526c-.433-.984-1.876-.133-1.446.845z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#f2f2f2"
        d="M252.459 770.473a82.967 82.967 0 01-92.692-43.74c-2.073-4.14-3.822-8.56-4.033-13.185s1.32-9.518 4.872-12.487c3.878-3.241 9.523-3.673 14.385-2.295s9.11 4.322 13.174 7.327c24.473 18.098 47.02 39.448 64.325 64.489z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#fff"
        d="M159.04 702.903l18.128 14.56 18.128 14.56c5.741 4.612 11.479 9.228 17.288 13.754 5.758 4.486 11.587 8.883 17.553 13.089 5.958 4.2 12.054 8.209 18.334 11.912q1.173.692 2.354 1.37c.546.312 1.072-.514.523-.83-6.324-3.628-12.463-7.571-18.457-11.722-6-4.155-11.855-8.516-17.63-12.977-5.806-4.485-11.532-9.072-17.252-13.666q-9.032-7.253-18.062-14.507l-18.128-14.56-2.256-1.812c-.49-.394-1.01.438-.522.83z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#3f3d56"
        d="M1024.033 772.808H196.739a1.19 1.19 0 110-2.381h827.294a1.19 1.19 0 110 2.38zM1002.3 653.515h-56.01a2.501 2.501 0 01-2.5 2.5h-54.98a12.86 12.86 0 00-2.11 2 12.41 12.41 0 00-2.9 8v2a12.505 12.505 0 0012.5 12.5h106a12.51 12.51 0 0012.5-12.5v-2a12.517 12.517 0 00-12.5-12.5z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path fill="#3f3d56" d="M790.228 552.827H803.228V644.827H790.228z"></path>
      <path
        fill="#3f3d56"
        d="M993.295 771.562c0 1.405-19.7.543-44 .543s-44 .862-44-.543 19.7-12.543 44-12.543 44 11.139 44 12.543z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#536dfe"
        d="M999.85 656.395l-2.18-13.64a6.574 6.574 0 00-7.52-5.45l-22.49 3.59-38.52 6.15-12.53 2h27.18a2.501 2.501 0 012.5 2.5v1.97a2.501 2.501 0 01-2.5 2.5h-69.17a6.332 6.332 0 00-2.43 2 6.487 6.487 0 00-1.24 4.96l.62 3.88.75 4.71.81 5.06a6.56 6.56 0 007.5 5.44l17.87-2.85 51.54-8.23 44.36-7.08a6.587 6.587 0 005.45-7.51z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#536dfe"
        d="M897.13 658.015a6.356 6.356 0 00-2.53-2h-19.12a6.321 6.321 0 00-2.54 2 6.465 6.465 0 00-1.37 3.99v76.21a6.56 6.56 0 006.55 6.55h13.83a6.56 6.56 0 006.55-6.55v-76.21a6.484 6.484 0 00-1.37-3.99zM938.75 528.215l-.13-.17-8.58-10.57a6.315 6.315 0 00-1.63-1.43 4.813 4.813 0 00-1.06-.54 6.3 6.3 0 00-1.71-.42 6.648 6.648 0 00-1.18-.02.032.032 0 00-.02.01 6.485 6.485 0 00-3.62 1.44l-17.61 14.28-22.63 18.36-33.77 27.4-2.46 2h42.73l2.47-2 8.17-6.63.58-.46 39.46-32.01.03-.02a6.457 6.457 0 001.87-2.45 6.6 6.6 0 00.52-1.97 6.501 6.501 0 00-1.43-4.8z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#2f2e41"
        d="M938.62 528.045a16.303 16.303 0 00-3.56-7.3 16.118 16.118 0 00-6.65-4.7c-.4-.15-.79-.29-1.19-.4a17.148 17.148 0 00-2.76-.58.032.032 0 00-.02.01 16.314 16.314 0 00-11.8 3.26l-30.46 23.28a6.462 6.462 0 00-2.15 4.57 6.633 6.633 0 00.55 2.97c.1.21.21.42.33.62a5.756 5.756 0 00.84 1.12l4.88 5.48 11.35 12.73.32.36.73.83a6.55 6.55 0 009.27.41l26.27-28.22a16.214 16.214 0 003.19-5.03 16.466 16.466 0 00.86-9.41z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <circle cx="797.167" cy="314.521" r="50.122" fill="#536dfe"></circle>
      <path
        fill="#536dfe"
        d="M969.03 649.495l-1.37-8.6-.81-5.05a6.563 6.563 0 00-7.51-5.44l-36.43 5.81-9.88 1.58-2 .32v10.93h32.76a2.501 2.501 0 012.5 2.5v1.97a2.501 2.501 0 01-2.5 2.5H840.12c0 .02.01.04.01.06l.62 3.88 1.56 9.76a6.56 6.56 0 007.5 5.44l17.87-2.85 3.89-.62.75-.12 26.18-4.18 17.81-2.85 28.26-4.51.42-.07 18.59-2.96a6.568 6.568 0 005.45-7.5z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#2f2e41"
        d="M1018.45 612.715c0 40.86-4.15 65.3-68.15 58.3l-.26-.03a190.03 190.03 0 01-33.73-6.45 100.174 100.174 0 01-16.64-6.52c-1.26-.64-2.47-1.31-3.66-2h47.78a2.501 2.501 0 002.5-2.5v-1.97a2.501 2.501 0 00-2.5-2.5h-32.76v-63.16a7.34 7.34 0 00-7.33-7.33h-26.65c.23-.66.46-1.33.71-2a163.83 163.83 0 018.87-20.18 153.933 153.933 0 0116.58-25.58 106.531 106.531 0 0114.84-15.19c10.03-8.28 20.95-13.4 32.03-13.4 40.86 0 68.37 69.65 68.37 110.51zM951.287 460.29l.052-1.874c-3.485-.096-6.557-.315-8.865-2.002a5.758 5.758 0 01-2.23-4.24 3.291 3.291 0 011.08-2.711c1.532-1.294 3.997-.875 5.794-.051l1.55.71-2.972-21.718-1.855.254 2.527 18.474a6.612 6.612 0 00-6.254.9 5.124 5.124 0 00-1.742 4.207 7.616 7.616 0 002.997 5.688c2.966 2.168 6.82 2.276 9.918 2.362z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path fill="#2f2e41" d="M771.509 303.059H781.597V304.932H771.509z"></path>
      <path fill="#2f2e41" d="M803.351 303.059H813.439V304.932H803.351z"></path>
      <path
        fill="#2f2e41"
        d="M990.235 427.941c-3.898-5.868-10.757-8.355-17.52-8.96-7.614-.681-14.97.787-22.403 2.213-8.18 1.57-16.838 3.039-25.081 1.054a24.725 24.725 0 01-16.807-13.578 31.627 31.627 0 01-1.842-21.088c1.932-7.48 6.726-14.06 12.538-19.032 11.863-10.145 27.916-14.704 43.357-14.275a73.301 73.301 0 0142.494 14.942 69.02 69.02 0 0124.486 38.37 71.952 71.952 0 01-3.485 45.258c-3.378 7.838-8.265 14.904-12.036 22.545-3.51 7.114-6.214 15.385-4.03 23.32 1.917 6.963 7.545 13.149 14.61 15.039a15.342 15.342 0 0017.06-6.965c3.532-5.569 3.616-12.863-1.687-17.36a13.016 13.016 0 00-18.777 1.874 13.398 13.398 0 00-2.68 9.824c.261 1.768-2.446 2.532-2.71.746a15.849 15.849 0 0129.551-10.233c3.198 6.012 1.472 13.264-2.587 18.404a17.938 17.938 0 01-18.289 6.576c-7.389-1.706-13.434-7.58-16.276-14.48-3.165-7.683-1.715-16.257 1.37-23.713 3.335-8.059 8.287-15.287 12.204-23.053a65.656 65.656 0 006.662-22.432 70.98 70.98 0 00-10.124-44.96 65.97 65.97 0 00-36.335-27.616 68.944 68.944 0 00-45.62 1.207c-14.277 5.625-28.062 17.883-27.897 34.347.07 7.033 2.682 14.205 8.069 18.896 6.437 5.606 15.401 6.085 23.494 5.21 8.144-.88 16.053-3.259 24.232-3.866 7.114-.527 14.79.021 21.142 3.556a21.138 21.138 0 017.343 6.812c1.003 1.51-1.429 2.919-2.426 1.418z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#536dfe"
        d="M980.03 542.545l-3.97-2.76-7.39-5.13a6.564 6.564 0 00-9.12 1.65l-24.09 34.67-22.43 32.28-2 2.88v42.91h.18a6.531 6.531 0 004.09-2 5.83 5.83 0 00.63-.77l6.98-10.06 17.25-24.83 17.88-25.72 1.7-2.45 21.92-31.55a5.682 5.682 0 00.33-.52 5.269 5.269 0 00.27-.53 6.55 6.55 0 00-2.23-8.07z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#2f2e41"
        d="M980.1 528.825a16.088 16.088 0 00-2.22-1.22 16.359 16.359 0 00-19.98 5.16l-22.76 30.86a6.525 6.525 0 002.18 9.04l20.72 13 .26.16 1.04.65a6.573 6.573 0 009.01-2.2l16.07-31.9.27-.53.01-.01.99-1.99a16.363 16.363 0 00-5.59-21.02z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#536dfe"
        d="M840.75 656.015v75.29a6.56 6.56 0 006.55 6.55h13.83a6.56 6.56 0 006.55-6.55v-75.29z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#3f3d56"
        d="M943.79 647.045h-30.76v-61.16a9.34 9.34 0 00-9.33-9.33H789.63a9.342 9.342 0 00-9.34 9.33v62.8a9.342 9.342 0 009.34 9.33h154.16c.16 0 .31-.01.46-.02.14-.01.28-.04.42-.06a4.516 4.516 0 003.62-4.42v-1.97a4.5 4.5 0 00-4.5-4.5zm0 8.97H789.63a7.343 7.343 0 01-7.34-7.33v-62.8a7.343 7.343 0 017.34-7.33H903.7a7.34 7.34 0 017.33 7.33v63.16h32.76a2.501 2.501 0 012.5 2.5v1.97a2.501 2.501 0 01-2.5 2.5z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <circle cx="693.595" cy="490.094" r="7.69" fill="#536dfe"></circle>
      <circle cx="121.996" cy="307.821" r="53.519" fill="#536dfe"></circle>
      <path
        fill="#2f2e41"
        d="M264.63 636.345h-14.76a6.506 6.506 0 00-6.5 6.5v123.01a6.506 6.506 0 006.5 6.5h14.76a6.506 6.506 0 006.5-6.5v-123.01a6.506 6.506 0 00-6.5-6.5zM295.82 636.345h-14.76a6.506 6.506 0 00-6.5 6.5v123.01a6.506 6.506 0 006.5 6.5h14.76a6.5 6.5 0 006.5-6.5v-123.01a6.5 6.5 0 00-6.5-6.5z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#2f2e41"
        d="M306.51 516.595a40.533 40.533 0 00-72.59 24.77v108.94a5.36 5.36 0 00.08.99 6.996 6.996 0 006.92 6.01h67.07a7.014 7.014 0 007-7v-108.94a40.25 40.25 0 00-8.48-24.77zM320.258 458.671l-.055-2c3.72-.103 7-.337 9.466-2.137a6.148 6.148 0 002.38-4.529 3.514 3.514 0 00-1.152-2.894c-1.636-1.382-4.269-.935-6.188-.055l-1.655.759 3.174-23.19 1.981.271-2.7 19.727c2.608-.767 5.024-.437 6.679.96a5.471 5.471 0 011.86 4.493 8.133 8.133 0 01-3.2 6.073c-3.167 2.314-7.283 2.43-10.59 2.522z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#2f2e41"
        d="M150.942 299.405H161.71300000000002V301.405H150.942z"
      ></path>
      <path
        fill="#536dfe"
        d="M259.37 518.135a6.45 6.45 0 00-4.52-2.09l-11.63-.48-3.11-.13a6.51 6.51 0 00-6.77 6.22l-1.84 44.32-3.25 78.59a6.51 6.51 0 005.75 6.73c.16.02.31.03.47.04l8.9.37 5.85.24a6.47 6.47 0 001.4-.09 6.535 6.535 0 003.27-1.62 6.45 6.45 0 002.09-4.52l.39-9.37 2.87-69.31 1.84-44.23a6.498 6.498 0 00-1.71-4.67z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#2f2e41"
        d="M250.85 508.435a18.089 18.089 0 00-3.78-.39 18.522 18.522 0 00-18.5 18.5v34a6.528 6.528 0 002.93 5.43 6.406 6.406 0 002.42.96 6.23 6.23 0 001.15.11h24a.92.92 0 00.17-.01 6.496 6.496 0 006.33-6.49v-34a18.53 18.53 0 00-14.72-18.11z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#536dfe"
        d="M379.03 605.265a7.484 7.484 0 01-3.7-8.91l5.69-18.11-1.66-1.38-43.67-36.13-29.18-24.14-3.55-2.94a6.5 6.5 0 00-9.15.86l-9.41 11.38a6.488 6.488 0 00.86 9.15l29.73 24.6 3.38 2.79 61.67 51.02a6.493 6.493 0 008.15.12 5.89 5.89 0 00.88-.85 1.38 1.38 0 00.12-.13l1.19-1.44z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#2f2e41"
        d="M333.59 533.795l-26.31-21.53a18.5 18.5 0 00-23.44 28.63l26.31 21.53a6.496 6.496 0 008.22.01 5.387 5.387 0 00.93-.92l15.2-18.57a6.427 6.427 0 001.19-2.21 6.535 6.535 0 00-2.1-6.94zM261.645 444.075c-2.787-4.656-4.968-9.786-3.932-15.303.924-4.925 4.469-8.762 9.566-9.265 3.07-.303 6.125.516 9.123 1.05a34.246 34.246 0 007.448.482 36.994 36.994 0 0014.451-3.664 35.47 35.47 0 0019.057-22.331c3.041-11.074.783-23.649-6.44-32.663l2.506-.662-3.264 10.724a1.515 1.515 0 01-2.203.897 51.664 51.664 0 00-40.201-6.427 48.947 48.947 0 00-11.031 4.242c-1.704.906-3.221-1.682-1.514-2.59a54.697 54.697 0 0142.914-3.287 52.214 52.214 0 0111.346 5.472l-2.204.896 3.265-10.724a1.512 1.512 0 012.507-.662 40.207 40.207 0 018.24 30.483 37.94 37.94 0 01-16.428 26.318 40.458 40.458 0 01-30.553 6.142c-2.936-.564-6.266-1.372-9.147-.143a7.9 7.9 0 00-4.234 5.208c-1.632 5.531 1.146 10.784 3.983 15.383z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#3f3d56"
        d="M456.89 554.875l-49.47-28.63a9.503 9.503 0 00-13.82 5.37l-14.24 45.25-5.94 18.89a9.483 9.483 0 004.69 11.28l10.96 5.69 35.76 18.55a9.478 9.478 0 004.38 1.07 9.655 9.655 0 003.3-.59l.07-.03a9.55 9.55 0 005.5-5.48l22.92-59.75a9.459 9.459 0 00-4.11-11.62zm2.24 10.91l-22.92 59.75a7.529 7.529 0 01-4.35 4.32l-.05.02a7.532 7.532 0 01-6.06-.37l-35.37-18.35-11.35-5.89a7.484 7.484 0 01-3.7-8.91l5.69-18.11 14.48-46.03a7.517 7.517 0 0110.91-4.24l49.47 28.63a7.47 7.47 0 013.25 9.18z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#536dfe"
        d="M440.292 574.02a2.99 2.99 0 01-1.434-.367l-33-18a3 3 0 012.873-5.268l33 18a3 3 0 01-1.439 5.634zM434.292 587.02a2.99 2.99 0 01-1.434-.367l-33-18a3 3 0 012.873-5.268l33 18a3 3 0 01-1.439 5.634zM429.292 601.02a2.99 2.99 0 01-1.434-.367l-33-18a3 3 0 012.873-5.268l33 18a3 3 0 01-1.439 5.634z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#3f3d56"
        d="M778.266 317.735H639.537a7.53 7.53 0 01-7.521-7.522v-175.5a7.53 7.53 0 017.521-7.52h138.729a7.53 7.53 0 017.521 7.52v175.5a7.53 7.53 0 01-7.521 7.522zM639.537 128.863a5.857 5.857 0 00-5.85 5.85v175.5a5.857 5.857 0 005.85 5.85h138.729a5.857 5.857 0 005.85-5.85v-175.5a5.857 5.857 0 00-5.85-5.85z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#536dfe"
        d="M697.361 211.55h-30.537a3.765 3.765 0 01-3.76-3.76v-36.036a3.765 3.765 0 013.76-3.76h30.537a3.765 3.765 0 013.76 3.76v36.036a3.765 3.765 0 01-3.76 3.76z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#ccc"
        d="M756.697 228.265H726.16a3.765 3.765 0 01-3.76-3.76v-36.037a3.765 3.765 0 013.76-3.76h30.537a3.765 3.765 0 013.76 3.76v36.036a3.765 3.765 0 01-3.76 3.76z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#ff6584"
        d="M709.061 279.243h-30.537a3.765 3.765 0 01-3.76-3.76v-36.036a3.765 3.765 0 013.76-3.76h30.537a3.765 3.765 0 013.76 3.76v36.036a3.765 3.765 0 01-3.76 3.76z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#3f3d56"
        d="M597.305 270.299a7.602 7.602 0 00-6.95-4.65h-138.73a7.524 7.524 0 00-7.52 7.52v175.5a7.524 7.524 0 007.52 7.52h138.73a7.53 7.53 0 007.52-7.52v-175.5a7.383 7.383 0 00-.57-2.87zm-1.1 178.37a5.857 5.857 0 01-5.85 5.85h-138.73a5.851 5.851 0 01-5.85-5.85v-175.5a5.858 5.858 0 015.85-5.85h138.73a5.88 5.88 0 015.43 3.68 6.493 6.493 0 01.22.66 5.795 5.795 0 01.2 1.51z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#3f3d56"
        d="M568.35 307.085h-41.786a3.343 3.343 0 110-6.686h41.786a3.343 3.343 0 010 6.686zM568.35 324.635h-41.786a3.343 3.343 0 110-6.686h41.786a3.343 3.343 0 110 6.686z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#536dfe"
        d="M505.124 334.628h-30.537a3.765 3.765 0 01-3.761-3.76V294.83a3.765 3.765 0 013.76-3.76h30.538a3.765 3.765 0 013.76 3.76v36.036a3.765 3.765 0 01-3.76 3.76z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#ccc"
        d="M568.06 360.57h-94.436a3.343 3.343 0 110-6.685h94.436a3.343 3.343 0 010 6.686zM568.06 378.12h-94.436a3.343 3.343 0 110-6.685h94.436a3.343 3.343 0 010 6.686zM568.06 395.67h-94.436a3.343 3.343 0 110-6.685h94.436a3.343 3.343 0 010 6.686zM568.06 413.22h-94.436a3.343 3.343 0 110-6.685h94.436a3.343 3.343 0 010 6.686zM568.06 430.77h-94.436a3.343 3.343 0 110-6.685h94.436a3.343 3.343 0 010 6.686z"
        transform="translate(-153.067 -127.192)"
      ></path>
      <path
        fill="#e6e6e6"
        d="M608.895 374.318H718.091V375.99H608.895z"
        transform="rotate(-43.593 427.931 502.938)"
      ></path>
      <path
        fill="#e6e6e6"
        d="M469.569 286.858L479.607 286.74 479.589 285.067 471.347 285.166 471.851 276.94 470.181 276.839 469.569 286.858z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M551.281 209.063L550.671 219.083 549.001 218.983 549.504 210.756 541.263 210.855 541.245 209.183 551.281 209.063z"
      ></path>
      <circle cx="510.426" cy="247.962" r="9.193" fill="#e6e6e6"></circle>
      <path
        fill="#e6e6e6"
        d="M502.895 196.318H612.091V197.99H502.895z"
        transform="rotate(-43.593 321.931 324.938)"
      ></path>
      <path
        fill="#e6e6e6"
        d="M363.569 108.858L373.607 108.74 373.589 107.067 365.347 107.166 365.851 98.94 364.181 98.839 363.569 108.858z"
      ></path>
      <path
        fill="#e6e6e6"
        d="M445.281 31.063L444.671 41.083 443.001 40.983 443.504 32.756 435.263 32.855 435.245 31.183 445.281 31.063z"
      ></path>
      <circle cx="404.426" cy="69.962" r="9.193" fill="#e6e6e6"></circle>
      <path fill="#3f3d56" d="M757.933 520.913H759.933V529.913H757.933z"></path>
    </svg>
  );
}

export default Icon;
