import React from "react";

function Icon() {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      width="529.16"
      height="328.58"
      data-name="Layer 1"
      viewBox="0 0 529.16 479.144"
      style={{maxWidth: '100%'}}
    >
      <path
        fill="#3f3d56"
        d="M765.724 403.199c-28.17-21.06-72.292-40.977-121.052-54.643-44.396-12.444-87.698-18.452-121.939-16.918l-.178-3.982c34.649-1.551 78.4 4.507 123.193 17.062 49.214 13.794 93.814 33.946 122.362 55.288z"
        transform="translate(-335.42 -210.428)"
      ></path>
      <path
        fill="#f2f2f2"
        d="M762.8 419.42a132.645 132.645 0 01-11.48 54.156q-.95 2.14-1.981 4.241A133.148 133.148 0 01496.54 419.42q0-4.622.315-9.172.15-2.313.387-4.6a133.136 133.136 0 01265.556 13.772z"
        transform="translate(-335.42 -210.428)"
      ></path>
      <path
        d="M751.32 473.576q-.95 2.14-1.981 4.241-5.836.634-12.285.921c-3.56.16-7.222.24-10.965.24-32.692 0-72.037-6.035-112.231-17.3-44.794-12.555-85.318-30.117-114.112-49.452q-1.471-.987-2.89-1.977.15-2.314.387-4.6 2.29 1.632 4.723 3.268c28.455 19.105 68.573 36.48 112.968 48.923 39.843 11.169 78.817 17.152 111.135 17.152q5.542 0 10.806-.236 7.665-.347 14.445-1.18z"
        opacity="0.1"
        transform="translate(-335.42 -210.428)"
        style={{ isolation: "isolate" }}
      ></path>
      <path
        fill="#3f3d56"
        d="M726.09 475.79c-32.692 0-72.039-6.035-112.231-17.3-44.793-12.555-85.32-30.118-114.114-49.453-29.395-19.738-43.18-39.188-38.813-54.766 4.366-15.577 26.25-25.03 61.623-26.615l.178 3.982c-16.518.74-29.98 3.196-40.011 7.298-10.007 4.091-16.047 9.613-17.952 16.411-1.906 6.798.385 14.655 6.807 23.351 6.438 8.718 16.663 17.812 30.39 27.03 28.452 19.105 68.572 36.48 112.968 48.924 39.844 11.167 78.815 17.152 111.135 17.152q5.54 0 10.804-.235c16.518-.74 29.98-3.196 40.011-7.297 10.007-4.092 16.047-9.614 17.952-16.412 3.421-12.207-6.918-28.068-29.113-44.661l2.386-3.192c11.422 8.538 19.877 16.955 25.13 25.016 5.632 8.642 7.46 16.687 5.435 23.913-4.366 15.578-26.25 25.03-61.623 26.615-3.559.16-7.22.238-10.962.238z"
        transform="translate(-335.42 -210.428)"
      ></path>
      <circle cx="297.772" cy="50.327" r="1.526" fill="#f2f2f2"></circle>
      <circle cx="95.385" cy="115.426" r="2.624" fill="#ccc"></circle>
      <circle cx="89.792" cy="218.145" r="5.185" fill="#ccc"></circle>
      <circle cx="400.999" cy="59.481" r="3.455" fill="#ccc"></circle>
      <circle cx="523.55" cy="202.89" r="5.61" fill="#ccc"></circle>
      <circle cx="479.682" cy="113.287" r="2.407" fill="#ccc"></circle>
      <circle cx="187.425" cy="82.364" r="3.755" fill="#ccc"></circle>
      <circle cx="471.257" cy="191.514" r="2.407" fill="#ccc"></circle>
      <circle cx="2.996" cy="200.371" r="2.996" fill="#ccc"></circle>
      <circle cx="48.508" cy="151.068" r="3.244" fill="#ccc"></circle>
      <path
        fill="#ccc"
        d="M256.822 6.402L250.42 6.402 250.42 0 246.985 0 246.985 6.402 240.58 6.402 240.58 9.84 246.985 9.84 246.985 16.242 250.42 16.242 250.42 9.84 256.822 9.84 256.822 6.402z"
      ></path>
      <path
        fill="#ccc"
        d="M405.335 352.829L401.314 352.829 401.314 348.81 399.158 348.81 399.158 352.829 395.138 352.829 395.138 354.986 399.158 354.986 399.158 359.006 401.314 359.006 401.314 354.986 405.335 354.986 405.335 352.829z"
      ></path>
      <path
        fill="#ccc"
        d="M498.123 332.252L495.118 332.252 495.118 329.247 493.504 329.247 493.504 332.252 490.499 332.252 490.499 333.866 493.504 333.866 493.504 336.871 495.118 336.871 495.118 333.866 498.123 333.866 498.123 332.252z"
      ></path>
      <circle
        cx="210.308"
        cy="186.618"
        r="11.187"
        opacity="0.1"
        style={{ isolation: "isolate" }}
      ></circle>
      <circle
        cx="333.876"
        cy="298.998"
        r="11.187"
        opacity="0.1"
        style={{ isolation: "isolate" }}
      ></circle>
      <circle
        cx="296.247"
        cy="203.907"
        r="11.187"
        opacity="0.1"
        style={{ isolation: "isolate" }}
      ></circle>
      <circle
        cx="215.902"
        cy="265.437"
        r="11.187"
        opacity="0.1"
        style={{ isolation: "isolate" }}
      ></circle>
      <circle
        cx="356.251"
        cy="143.903"
        r="11.187"
        opacity="0.1"
        style={{ isolation: "isolate" }}
      ></circle>
      <circle
        cx="393.372"
        cy="219.671"
        r="11.187"
        opacity="0.1"
        style={{ isolation: "isolate" }}
      ></circle>
      <circle cx="356.251" cy="97.12" r="14.747" fill="#fff"></circle>
      <path
        fill="#536dfe"
        d="M691.67 286.19a20.849 20.849 0 00-20.848 20.85c0 11.514 20.848 49.325 20.848 49.325s20.85-37.811 20.85-49.326a20.849 20.849 0 00-20.85-20.848zm0 31.02a9.662 9.662 0 119.662-9.662 9.662 9.662 0 01-9.662 9.662z"
        transform="translate(-335.42 -210.428)"
      ></path>
      <circle cx="210.251" cy="137.12" r="14.747" fill="#fff"></circle>
      <path
        fill="#3f3d56"
        d="M545.67 326.19a20.849 20.849 0 00-20.848 20.85c0 11.514 20.848 49.325 20.848 49.325s20.85-37.811 20.85-49.326a20.849 20.849 0 00-20.85-20.848zm0 31.02a9.662 9.662 0 119.662-9.662 9.662 9.662 0 01-9.662 9.662z"
        transform="translate(-335.42 -210.428)"
      ></path>
      <circle cx="395.251" cy="171.12" r="14.747" fill="#fff"></circle>
      <path
        fill="#ccc"
        d="M730.67 360.19a20.849 20.849 0 00-20.848 20.85c0 11.514 20.848 49.325 20.848 49.325s20.85-37.811 20.85-49.326a20.849 20.849 0 00-20.85-20.848zm0 31.02a9.662 9.662 0 119.662-9.662 9.662 9.662 0 01-9.662 9.662z"
        transform="translate(-335.42 -210.428)"
        style={{ isolation: "isolate" }}
      ></path>
      <circle cx="145.251" cy="245.12" r="14.747" fill="#fff"></circle>
      <path
        fill="#536dfe"
        d="M480.67 434.19a20.849 20.849 0 00-20.848 20.85c0 11.514 20.848 49.325 20.848 49.325s20.85-37.811 20.85-49.326a20.849 20.849 0 00-20.85-20.848zm0 31.02a9.662 9.662 0 119.662-9.662 9.662 9.662 0 01-9.662 9.662z"
        transform="translate(-335.42 -210.428)"
      ></path>
      <ellipse
        cx="806.286"
        cy="557.447"
        fill="#2f2e41"
        rx="6.76"
        ry="21.534"
        transform="rotate(-39.938 349.019 913.785)"
      ></ellipse>
      <circle cx="425.059" cy="339.542" r="43.067" fill="#2f2e41"></circle>
      <path fill="#2f2e41" d="M430.204 373.341H443.288V396.783H430.204z"></path>
      <path fill="#2f2e41" d="M404.037 373.341H417.121V396.783H404.037z"></path>
      <ellipse
        cx="432.385"
        cy="397.055"
        fill="#2f2e41"
        rx="10.903"
        ry="4.089"
      ></ellipse>
      <ellipse
        cx="406.217"
        cy="396.51"
        fill="#2f2e41"
        rx="10.903"
        ry="4.089"
      ></ellipse>
      <path
        fill="#536dfe"
        d="M746.956 495.833c3.846-15.487 20.82-24.601 37.915-20.357s27.834 20.24 23.989 35.728-16.604 15.537-33.699 11.292-32.05-11.176-28.205-26.663z"
        transform="translate(-335.42 -210.428)"
      ></path>
      <ellipse
        cx="711.971"
        cy="529.986"
        fill="#2f2e41"
        rx="6.76"
        ry="21.534"
        transform="rotate(-64.626 377.912 689.93)"
      ></ellipse>
      <circle cx="418.621" cy="330.454" r="14.359" fill="#fff"></circle>
      <circle cx="412.719" cy="325.307" r="4.786" fill="#3f3d56"></circle>
      <path
        fill="#fff"
        d="M763.048 570.797a9.572 9.572 0 11-18.836 3.43l-.003-.02c-.942-5.201 3.08-7.042 8.283-7.984s9.614-.628 10.556 4.574z"
        transform="translate(-335.42 -210.428)"
      ></path>
      <path
        fill="#3f3d56"
        d="M671.784 458.477a29.898 29.898 0 101.695 44.154l36.673 30.945a2.755 2.755 0 003.558-4.208l-.004-.003-36.674-30.945a29.9 29.9 0 00-5.248-39.943zm-2.365 36.963a22.4 22.4 0 11-2.674-31.565 22.4 22.4 0 012.674 31.565z"
        transform="translate(-335.42 -210.428)"
      ></path>
      <path
        d="M637.854 498.114a22.401 22.401 0 01-3.8-30.112q-.643.647-1.243 1.355a22.4 22.4 0 1034.24 28.89q.598-.708 1.126-1.452a22.401 22.401 0 01-30.323 1.32z"
        opacity="0.3"
        transform="translate(-335.42 -210.428)"
        style={{ isolation: "isolate" }}
      ></path>
      <ellipse
        cx="477.089"
        cy="517.447"
        fill="#2f2e41"
        rx="21.534"
        ry="6.76"
        transform="rotate(-69.082 156.527 655.878)"
      ></ellipse>
      <circle cx="101.862" cy="339.541" r="43.067" fill="#2f2e41"></circle>
      <path
        fill="#2f2e41"
        d="M82.236 373.341H95.32000000000001V396.783H82.236z"
      ></path>
      <path fill="#2f2e41" d="M108.404 373.341H121.488V396.783H108.404z"></path>
      <ellipse
        cx="93.139"
        cy="397.055"
        fill="#2f2e41"
        rx="10.903"
        ry="4.089"
      ></ellipse>
      <ellipse
        cx="119.307"
        cy="396.51"
        fill="#2f2e41"
        rx="10.903"
        ry="4.089"
      ></ellipse>
      <circle cx="102.952" cy="328.638" r="14.719" fill="#fff"></circle>
      <circle cx="102.952" cy="328.638" r="4.906" fill="#3f3d56"></circle>
      <path
        fill="#536dfe"
        d="M395.505 509.918c-3.477-15.574 7.639-31.31 24.829-35.149s33.944 5.675 37.421 21.249-7.915 21.318-25.105 25.156-33.668 4.318-37.145-11.256z"
        transform="translate(-335.42 -210.428)"
      ></path>
      <ellipse
        cx="388.774"
        cy="529.986"
        fill="#2f2e41"
        rx="6.76"
        ry="21.534"
        transform="rotate(-64.626 54.714 689.93)"
      ></ellipse>
      <path
        fill="#fff"
        d="M416.127 565.506c0 4.215 10.854 12.539 22.897 12.539s23.335-11.867 23.335-16.082-11.292.818-23.335.818-22.897-1.49-22.897 2.725z"
        transform="translate(-335.42 -210.428)"
      ></path>
      <circle cx="241.581" cy="417.541" r="43.067" fill="#2f2e41"></circle>
      <path fill="#2f2e41" d="M221.956 451.341H235.04V474.783H221.956z"></path>
      <path fill="#2f2e41" d="M248.123 451.341H261.207V474.783H248.123z"></path>
      <ellipse
        cx="232.859"
        cy="475.055"
        fill="#2f2e41"
        rx="10.903"
        ry="4.089"
      ></ellipse>
      <ellipse
        cx="259.026"
        cy="474.51"
        fill="#2f2e41"
        rx="10.903"
        ry="4.089"
      ></ellipse>
      <path
        fill="#3f3d56"
        d="M535.224 587.918c-3.477-15.574 7.639-31.31 24.829-35.149s33.944 5.675 37.422 21.25-7.915 21.317-25.105 25.155-33.668 4.318-37.146-11.256z"
        transform="translate(-335.42 -210.428)"
      ></path>
      <path
        fill="#536dfe"
        d="M577.174 620.507s3.124-13.746-2.812-13.121-14.058.937-16.245.937-14.684-1.562-16.558 6.56-4.374 18.12.937 24.369c0 0 1.875 27.492 9.06 29.366s26.243 4.062 33.116-4.998a23.3 23.3 0 004.061-19.058h-3.124z"
        transform="translate(-335.42 -210.428)"
      ></path>
      <path
        d="M545.62 641.438s5.311 2.5 16.246.938v4.686l-16.246-1.25z"
        opacity="0.1"
        transform="translate(-335.42 -210.428)"
        style={{ isolation: "isolate" }}
      ></path>
      <path
        fill="#536dfe"
        d="M585.311 657.103l-3.536-1.87 15.73-29.765a7.431 7.431 0 00-6.371-10.9l-16.507-.445.107-3.999 16.508.445a11.432 11.432 0 019.799 16.768zM547.294 657.103l-15.73-29.766a11.432 11.432 0 019.799-16.768l16.508-.445.107 4-16.507.444a7.431 7.431 0 00-6.37 10.9l15.73 29.766z"
        transform="translate(-335.42 -210.428)"
      ></path>
      <ellipse
        cx="611.105"
        cy="627.848"
        fill="#2f2e41"
        rx="7.501"
        ry="23.892"
        transform="rotate(-62.122 268.71 801.08)"
      ></ellipse>
    </svg>
  );
}

export default Icon;
