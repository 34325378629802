import React from 'react';
// import Button from '../components/Button';
// import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
// import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
// import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
// import HeroImage from '../svg/HeroImage';
// import SvgCharts from '../svg/SvgCharts';
import WhyC4 from '../svg/WhyC4';
import Collaborate from '../svg/Collaborate';
import Extended from '../svg/Extended'
import Remote from '../svg/Remote'
import {
  AnnotationIcon, GlobeAltIcon, LightningBoltIcon, ScaleIcon
} from '@heroicons/react/outline'
import Development from '../svg/Development'
import { AiFillCheckCircle } from "react-icons/ai";
import FAQ from '../components/FAQ'
import { CheckIcon } from '@heroicons/react/solid'
import { Helmet } from "react-helmet"
import './how-it-works.css';


const features = [
  {
    name: 'Flexible hiring!',
    description:
      'C4Scale allows you to elastically resize teams based on your needs.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Lean Agile',
    description:
      'Our engineers understand the rationale of lean teams and agile. Rapid, Iterative development with commitment are our key principles',
    icon: ScaleIcon,
  },
  {
    name: 'Highly Competent for a fair price!',
    description:
      'Global standards at a fair price! Our hiring bars are high as we know what is committed, dedicated, professional competent talent means.',
    icon: LightningBoltIcon,
  },
  // {
  //   name: 'Support',
  //   description:
  //     'Our engineers will support in production battles. We can customize support based on your needs.',
  //   icon: AnnotationIcon,
  // },
]

const Benefits = () => {
  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h1 className="text-3xl tracking-tight font-[700] text-gray-900 sm:text-4xl mb-3">
            <span className="block xl:inline">Benefits of</span>{' '}
            <span className="block text-indigo-600 xl:inline">choosing C4Scale</span>
          </h1>
          {/* <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Benefits Of Choosing Us</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            A better way to send money
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-600 lg:mx-auto">
            Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in
            accusamus quisquam.
          </p> */}
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}


const steps = [
  { id: '01', name: 'Sprint Planning/Roadmaps', description: 'Sprint roadmap contains backlog of prioritized features. Our team members collaborate to plan and prioritize the items', href: '#', status: 'current' },
  { id: '02', name: 'Tech Architecture', description: 'We break down the feature into decoupled building blocks/components. This lets the team to work independently.', href: '#', status: 'current' },
  { id: '03', name: 'Standups & Showcases', description: 'Daily Stand ups, sprint demos, retros, reviews, user acceptance keeps the development work per the expectations', href: '#', status: 'current' },
  { id: '04', name: 'Code reviews', description: 'Code reviews help standardize, enforce code quality, helps remove bugs, code smells', href: '#', status: 'current' },
  /* { id: '05', name: 'Maintenance', href: '#', status: 'current' }, */
]

const Steps = () => {
  return (
    <>
    <h1 className="text-3xl tracking-tight font-bold text-gray-900 sm:text-4xl text-center py-10">How we develop
            <span className="block text-indigo-600 xl:inline"> software</span>
          </h1>
    <nav aria-label="Progress">
      <ol className="border border-indigo-600 rounded-md divide-y divide-gray-600 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex-1 md:flex" style={{flexDirection: 'column', padding: '1rem'}}>
            {step.status === 'complete' ? (
              <a href={step.href} className="group flex items-center w-full">
                <span className="px-6 py-4 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800">
                    <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                </span>
              </a>
            ) : step.status === 'current' ? (
              <>
              <a href={step.href} className="px-6 py-4 flex items-center text-sm font-medium" aria-current="step">
                <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full">
                  <span className="text-indigo-600">{step.id}</span>
                </span>
                  <span className="ml-4 text-sm font-medium text-indigo-600">{step.name}</span>
              </a>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                  <p className="ml-4 text-sm font-medium text-blue-600">{step.description}</p>
                </div>
              </>
            ) : (
                  <a href={step.href} className="group flex items-center">
                    <span className="px-6 py-4 flex items-center text-sm font-medium">
                      <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full group-hover:border-indigo-600">
                        <span className="text-gray-600 group-hover:text-gray-900">{step.id}</span>
                      </span>
                      <span className="ml-4 text-sm font-medium text-gray-600 group-hover:text-gray-900">{step.name}</span>
                    </span>
                  </a>
                )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                  <svg
                    className="h-full w-full text-indigo-600"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
    </>
  )
}

const Index = () => (
  <Layout>
    <Helmet>
          <meta charSet="utf-8" />
          <title>C4Scale - How it works</title>
          <meta name="description" content="Hire Remote Engineers From C4Scale !!!" />
        </Helmet>
    {/* <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-12 xl:pr-28">
          <h3 className="text-3xl font-semibold leading-tight">Why C4Scale</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            We are a people-first company, passionate about building innovative software. 
Our motive is to bring opportunities across the globe to top talent. We do this by tapping into engineering talent nurturing them to do high impact, meaningful work.
          </p>
        </div>
      }
      secondarySlot={<WhyC4 />}
    /> */}
    
    {/* <section   style={{marginTop: 0}}>
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-12 xl:pl-28">
          <h3 className="text-3xl font-semibold leading-tight">
            Collaborating with C4Scale
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Collaborating with C4Scale means that you trust us to <span className="underline-custom-text">deliver work well tested and on time</span>. You believe in our agile teams that focus on delivering value &amp; impact for your business.
          </p>
        </div>
      }
      secondarySlot={<Collaborate />}
    />
    </section> */}
    <div className="wrapper">
    <h1 className="text-3xl tracking-tight font-bold text-gray-900 sm:text-4xl text-center py-10">Your engagement
            <span className="block text-indigo-600 xl:inline"> with us</span>
          </h1>
        <input type="radio" name="slider" defaultChecked id="home" />
        <input type="radio" name="slider" id="blog" />
        <input type="radio" name="slider" id="code" />
        <nav className="sm:flex sm:flex-nowrap flex-wrap ">
          <label htmlFor="home" className="home"><i className="fas fa-home" />Product Development Teams</label>
          <label htmlFor="blog" className="blog"><i className="fas fa-blog" />Extended Team</label>
          <label htmlFor="code" className="code"><i className="fas fa-code" />Remote/Onsite Teams</label>
          <div className="slider" />
        </nav>
        <section>
          <div className="content content-1">
            <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-12">
          <h3 className="pb-10 text-3xl font-semibold leading-tight">
            Product Development Teams
          </h3>
          <p className="text-lg text-gray-600 leading-relaxed">
           This model is relevant if you’re an enterprise, entrepreneur or a start up with a high vision to <span className="underline-custom-text">build a product</span> however you need a <span className="underline-custom-text">dedicated team </span> to build it on your behalf. Some of the situations that leads to this model:
          </p>
          <p className="text-lg text-gray-600 leading-10">
           <ul>
                <li><AiFillCheckCircle style={{display: 'inline', marginRight: '10px', color: '#4199e1'}}/>You need to prototype an idea and find market fit</li>
                <li><AiFillCheckCircle style={{display: 'inline', marginRight: '10px', color: '#4199e1'}}/>You have a prototype and you want to build a complete product with a mobile app and/or a website, backend</li>
                <li><AiFillCheckCircle style={{display: 'inline', marginRight: '10px', color: '#4199e1'}}/>You need to scale from 0 to 1</li>
                <li><AiFillCheckCircle style={{display: 'inline', marginRight: '10px', color: '#4199e1'}}/>You need to scale from 1 to destination</li>
           </ul>
          </p>
        </div>
      }
      secondarySlot={<Development />}
    />
          </div>
          <div className="content content-2">
              <h2 class="pb-10 text-3xl font-semibold leading-tight">Extended Team/Staff Augmentation</h2>
            <p class="mt-4 text-lg text-gray-600">You have software development processes &amp; teams already in place and now you need to scale your existing teams. You are looking for <span className="underline-custom-text">specialists to fill in gaps in expertise/skills in your teams</span>. This situation may arise if:</p>
            <SplitSection
      primarySlot={
        <div className="lg:pr-12 xl:pr-28">
          <p className="mt-8 text-lg text-gray-600 leading-relaxed">
            <ul>
                <li><AiFillCheckCircle style={{display: 'inline', marginRight: '10px', color: '#4199e1'}}/>You’ve budgeted constraints but need to scale the throughput and therefore need high quality expertise for a fair price</li>
                <li><AiFillCheckCircle style={{display: 'inline', marginRight: '10px', color: '#4199e1'}}/>You have shortage of talent supply</li>
            </ul>
        </p> 
        <p className="text-lg text-gray-600 leading-relaxed">
        In this case, we support you to fulfill your needs by:
            <ul>
                <li><AiFillCheckCircle style={{display: 'inline', marginRight: '10px', color: '#4199e1'}}/>providing skilled, technology experts that have high industry standards</li>
                <li><AiFillCheckCircle style={{display: 'inline', marginRight: '10px', color: '#4199e1'}}/>Recruiting and filling these roles based on your requirements</li>
            </ul>
          </p>
        </div>
      }
      secondarySlot={<Extended />}
    />
     <div class=" py-10">
      <p class="mt-4 text-lg text-gray-600">This also means that you have complete control over the product architecture, SDLC processes, tooling, project delivery etc.</p>
    </div>
          </div>
          <div className="content content-3">
            <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-12 xl:pr-28">
          <h3 className="text-3xl font-semibold leading-tight">Remote/Onsite Teams</h3>
          <p className="text-lg text-gray-600 leading-relaxed py-10">
           This model applies if you have <span className="underline-custom-text">long-term projects </span> and want to have <span className="underline-custom-text">dedicated teams</span> working on them. As an example a full stack team with front-end &amp; backend developers and a scrum master to drive development, test phases in an agile way &amp; managing project delivery. This can be a self-sustaining remote team and flexible to work in times overlapping your timezone.
          </p>
          <p className="text-lg text-gray-600 leading-relaxed">
           You can consider this as your cross-functional team working along with other teams of yours. In this case there may be shared responsibility in owning from gathering requirements to architecture/design/implementation.
          </p>
        </div>
      }
      secondarySlot={<Remote />}
    />
          </div>
        </section>
      </div>
   
      <section style={{marginBottom: 0}} className='bg-gray-50'>
        <div className="sectionWrapper" style={{marginBottom: 0}}>
          {Benefits()}
        </div>
      </section>
  
    {/* <div class="max-w-3xl mx-auto text-center">
      <p class="mt-4 text-lg text-gray-600">In this case, it is expected that you trust us to do the following:</p>
    </div> */}
    <section >
    <div className="stepsWrapper">
        {Steps()}
      </div>
      <br/>
    </section>
    {/* <div class="max-w-3xl mx-auto">
      <p class="mt-4 text-lg text-gray-600">We are your outsourced partner and bring your vision to life. You have complete ownership, copyrights of your product.</p>
      <p class="mt-4 text-lg text-gray-800">Support - <span class="mt-4 text-lg text-gray-600">We can custom build a support agreement as per your needs</span></p>
      <p class="mt-4 text-lg text-gray-800">Flexible hiring - <span class="mt-4 text-lg text-gray-600">You have 100% flexibility to replace, let go off an engineer. Of course, give us a month’s notice.</span></p>
    </div> */}
    {/* <section id="testimonials" className="py-12">
      <div className="container mx-auto pr-0 pl-0">
        <h1 className="text-4xl tracking-tight font-[700] text-gray-900 sm:text-5xl md:text-6xl pb-4 sentence mb-4">
          <span className="block xl:inline">How to </span>{' '}
          <span className="block text-indigo-600 xl:inline pb-4"><span className="underline-custom">start engagement</span> with C4Scale</span>
        </h1>
      
        <div className="flex flex-col md:flex-row md:-mx-3" style={{flexWrap: 'wrap'}}>
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3" style={{minWidth: '350px'}}>
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section> */}
    <FAQ/>
  </Layout>
);

export default Index;
